.gifBanner {
    background-image: url('../../public/images/mb.png') !important;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 70px 0px;
    background-position: right;
    background-color: #b0eaf2;
}
@media only screen and (max-width: 800px) {
    .gifBanner {
        background-image: none !important;
        padding: 20px 0px;
    }
}
