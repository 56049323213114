.changeBanner {
    background-image: url('../../public//images/img.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0px !important;
    color: black;
    /* background: #fffbdf !important; */
}
.changeBanner h1,
.changeBanner p {
    color: white;
}
@media only screen and (max-width: 800px) {
    .changeBanner {
        padding: 0px 0px !important;
    }
}
